<template>
	<div class="opacity-page">
		<div class="header-filter">
			<div class="row-space-tbf search-row">
				<div class="space-left"><icon-search v-if="$resize && $mq.above(781)"/></div>
				<div class="content">
					<div class="search-input">
						<input type="text" placeholder="Cauta dupa nume" v-model="search_filter"/>
					</div>
					<div class="actions-page mobile">
						<button class="btn-tbf center blue" id="buttonSync" @click="$router.push({name: 'admin-future-updates-create'})">
							<div class="loader"></div>
							<div class="text">{{ $t('general.add') }}</div>
						</button>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
		</div>

		<template v-if="loadedList">
			<div class="total-entries row-space-tbf">
				<div class="space-left"></div>
				<div class="content">
					<div class="count-entries">{{ $t('admin_users.filters.total_entries') }}<span class="number">{{ filteredSoftwareUpdates.length }}</span></div>
				</div>
				<div class="space-right"></div>
			</div>

			<div class="data-list list-software-updates" v-if="filteredSoftwareUpdates.length">
				<div class="row-space-tbf header-list">
					<div class="space-left"></div>
					<div class="content">
						<div class="column-filter column-master_inst_id">
							<div class="text">ID</div>
						</div>
						<div class="column-filter column-name">
							<div class="text">Nume</div>
						</div>
						<div class="column-filter column-softs">
							<div class="text">Softuri</div>
						</div>
						<div class="column-filter column-tag">
							<div class="text">Tag</div>
						</div>
						<div class="column-filter column-updated_at">
							<div class="text">Creat la</div>
						</div>
					</div>
					<div class="space-right"></div>
				</div>

				<template v-for="softwareUpdate in filteredSoftwareUpdates">
					<div class="row-space-tbf row-list-item" :key="softwareUpdate.id">
						<div class="space-left"><icon-people v-if="$resize && $mq.above(781)"/></div>
						<div class="content">
							<div class="border-hover" v-if="$resize && $mq.above(781)"></div>
							<div class="column-simple-text column-master_inst_id">
								<div class="text">{{ softwareUpdate.id }}</div>
							</div>
							<div class="column-name-title column-name link">
								<div class="name" @click="$router.push({name: 'admin-future-updates-edit', params: {id: softwareUpdate.id} })">{{ softwareUpdate.name }}</div>
							</div>
							<div class="column-simple-text column-softs">
								<div class="text">{{ $store.state.applications.list.filter(el =>  softwareUpdate.application_ids.includes(el.id)).map(el => { return el.name }).join(", ") }}</div>
							</div>
							<div class="column-simple-text column-tag">
								<div class="text">{{ softwareUpdate.tag }}</div>
							</div>
							<div class="column-simple-text column-updated_at">
								<div class="text">{{ softwareUpdate.created_at | moment('DD MMM YYYY') }}</div>
							</div>
						</div>
						<div class="space-right">
							<div class="dropdown dropdown-edit">
								<div class="button-edit" :ref="'dropdownEditRef'+softwareUpdate.id" :id="'dropdownEdit'+softwareUpdate.id" data-toggle="dropdown">
									<icon-edit />
								</div>
								<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu'+ softwareUpdate.id " :aria-labelledby="'dropdownEdit'+softwareUpdate.id">
									<div class="dropdown-item" @click="$router.push({name: 'admin-future-updates-edit', params: {id: softwareUpdate.id} })"><div class="simple-text">{{$t('general.edit')}}</div></div>
									<div class="dropdown-item" @click="showModal('delete', {type: 'softwareUpdate', from: 'index_software_updates', model: softwareUpdate})"><div class="simple-text">{{$t('general.delete')}}</div></div>
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>
			<div class="row-space-tbf list-empty" v-else>
				<div class="space-left"></div>
				<div class="content full">
					<div class="title">{{ $t('admin_users.empty_title')}}</div>
					<div class="description" v-html="$t('admin_users.empty_users_text')"></div>
				</div>
				<div class="space-right"></div>
			</div>
		</template>
		<loader-items-list class="position-absolut-list-loader" v-else/>
	</div>
</template>

<script>
    import IconSearch from '../../Icons/Search'
    import IconPeople from '../../Icons/People'
    import IconEdit from '../../Icons/EditDots'

    export default {
    	components: {
            IconSearch,
            IconPeople,
            IconEdit
        },
        data() {
            return {
				loadedList: true,
            	search_filter: '',
            	softwareUpdates: [],
            }
        },
        computed: {
        	filteredSoftwareUpdates(){
				return getByKeywordFilter(this.softwareUpdates, this.search_filter)
        	}
        },
        async mounted(){
			setTimeout(() => {
				this.$root.$emit("navbar_title", 'Viitoare actualizari');
			}, 0)
			await this.getSoftwareUpdates();

			this.$root.$on('refreshSoftwareUpdatesIndex', () => {
				this.getSoftwareUpdates();
			})
        },
        methods: {
        	async getSoftwareUpdates(){
        		await axios.get('/software-updates', { params: {type: 'work_in_progress'} })
        		.then(({data}) => {
        			this.softwareUpdates = data.data
        		})
        		.finally(() => {
	        		setTimeout(() => {
	        			this.loaded = true
	        			setTimeout(() => {
	        				$('.opacity-page').addClass('show')
	        			}, 0)
	        		}, 0)
        		})
        	},
			showModal(type, data = false){
				this.$root.$emit('open_modal', type, data);
			}
        }
    }

	function getByKeywordFilter(list, keyword){
		const search = keyword.trim()

		if (!search.length) return list
		return list.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1 || item.discount_token.toLowerCase().indexOf(search.toLowerCase()) > -1)
	}
</script>

<style lang="scss">
    .total-entries{
    	padding: 15px 0;
    	.count-entries{
    		font-size: 14px;
    		.number{
    			font-weight: 500;
    		}
    	}
    }

    .mt-second-filter{
    	margin-top: 15px;
    }

    .list-software-updates{
    	.row-list-item{
    		&:hover{
    			.content{
    				.column-name{
    					.name{
    						font-weight: 700;
    					}
    				}
    			}
    		}
    	}
    	.column-name{
    		flex: 1 1 auto;
    		display: flex;
    		align-items: center;
    		.status{
    			height: 10px;
    			width: 10px;
    			border-radius: 50%;
    			margin-right: 10px;
    			&.green {
    				border: 1px solid #03AE58;
    				background-color: rgba(11, 203, 106, 0.297449);;
    			}
    			&.red {
    				border: 1px solid #eb1b68;
    				background-color: rgba(235,27,104,.2);
    			}	
    		}
    		&.link{
    			cursor: pointer;
    		}
    	}
    	.column-master_inst_id{
    		flex: 0 0 60px;
    	}
    	.column-updated_at{
    		flex: 0 0 100px;
    	}
    	.column-tag{
    		flex: 0 0 200px;
    	}
    	.column-softs{
    		flex: 0 0 200px;
    	}
    }
</style>